'use strict'

import { IDocumentService, ILogService, IRootScopeService, IScope } from "angular";
import MapService from "../../../../services/map.service";
import * as L from 'leaflet';
import { EVehicleStatus, VehicleLocationResponse, StatusEntryResponse, VehicleLocationChangeData, VehicleMarkerHolder, VehicleTrackingMode} from "../../../../data/vehicles.data";
import PrivilegeService from "../../../../services/privilege.service";
import VehicleService from "../../../../services/vehicle.service";
import { RolePrivilege } from "../../../../data/privileges.enum";
import { Settings } from "../../../../data/account.data";
import RestService from "../../../../services/rest.service";
import { MissionRemovedFromMapResponse, VehicleAssignedAlarmCombined, VehicleRemovedFromAlarm } from "../../../../data/alarm.data";
import { EQueueEntryState, QueueEntryResponse } from "../../../../data/queue.data";
import HelperService from "../../../../services/helper.service";

require("./wache.map.component.scss")
require("./MarkerCluster.css")
require("./MarkerCluster.Default.css")

export default class WacheMapComponent {
  public restrict: string;
  public template: any;
  public scope: any;
  public controller: any;
  public controllerAs: string;
  public bindToController: boolean;

  constructor() {
    this.restrict = 'E';
    this.template = require('./wache.map.component.html');
    this.scope = {
      enableAlarmMapFeature: '='
    }
    this.controller = WacheMapComponentController
    this.controllerAs = 'ctrl';
    this.bindToController = true;
  }
}


class WacheMapComponentController {

  private map: any;
  private trackingClusterGroup: L.markerClusterGroup;

  private showStatus: Map<EVehicleStatus, boolean> = new Map<EVehicleStatus, boolean>();
  public readonly availableStatus = [EVehicleStatus.STATUS_1, EVehicleStatus.STATUS_2, EVehicleStatus.STATUS_3,
  EVehicleStatus.STATUS_4, EVehicleStatus.STATUS_6, EVehicleStatus.STATUS_7,
  EVehicleStatus.STATUS_8, EVehicleStatus.STATUS_C];
  private readonly alwaysShowStatus: Set<EVehicleStatus> = new Set([EVehicleStatus.STATUS_5, EVehicleStatus.STATUS_0, EVehicleStatus.STATUS_NOTRUF]);

  public statusColorMapping: Map<EVehicleStatus, string> = new Map<EVehicleStatus, string>();
  public statusTextColorMapping: Map<EVehicleStatus, string> = new Map<EVehicleStatus, string>();
  public statusTranslationMapping: Map<EVehicleStatus, string> = new Map<EVehicleStatus, string>();

  private center = [49.0, 9.0];
  private hasStationLocation = false;

  private vehicleIdMapping: Map<string, VehicleMarkerHolder> = new Map<string, VehicleMarkerHolder>();

  private listeners = [];

  // Alarm-Queue

  public enableAlarmMapFeature: boolean = true;

  // Layer
  private displayQueueNewAndInProgress: boolean = true;
  private displayQueueDone: boolean = false;
  private displayMissions: boolean = true;
  private displayWarnings: boolean = false;
  private displayRainRadar: boolean = false;
  private displayTracking: boolean = true;
  private fitBoundsActive: boolean = true;
  private markers: Map<String, L.Marker> = new Map<String, L.Marker>();
  private vehicleIdMarkerMapping: Map<String, L.Marker> = new Map<String, L.Marker>();
  private missionMap: Map<String, VehicleAssignedAlarmCombined> = new Map<String, VehicleAssignedAlarmCombined>();

  private queueLayerGroup: L.featureGroup;
  private queueDoneLayerGroup: L.featureGroup;
  private missionsLayerGroup: L.featureGroup;
  public rainRadarLayer: L.TileLayer.WMS;
  public warningsLayer: L.TileLayer.WMS;
  private refreshTimer;



  constructor(private $scope: IScope,
    private $rootScope: IRootScopeService,
    private $log: ILogService,
    private $document: IDocumentService,
    private mapService: MapService,
    private privilegeService: PrivilegeService,
    private vehicleService: VehicleService,
    private restService: RestService,
    private helperService: HelperService,
    private dataService: any) {

    this.displayRainRadar = this.helperService.getFromStorage('displayRainRadar', false);
    this.displayWarnings = this.helperService.getFromStorage('displayWarnings', false);
    this.displayQueueNewAndInProgress = this.helperService.getFromStorage('displayQueueNewAndInProgress', true);
    this.displayQueueDone = this.helperService.getFromStorage('displayQueueDone', false);
    this.displayMissions = this.helperService.getFromStorage('displayMissions', true);
    this.displayTracking = this.helperService.getFromStorage('displayTracking', true);

    this.$document.ready(() => {

      if (this.dataService.hasAccount()) {
        this.init();
      } else {
        //Wait for new account
        this.listeners.push(this.$rootScope.$on('new.account', () => {
          //Init controller
          this.init();
        }));
      }

    });
    // Unregister
    this.$scope.$on('$destroy', () => {
      //Each listener has a unregister function. They are stored in listeners array
      this.listeners.forEach((listener) => {
        listener();
      });
      clearInterval(this.refreshTimer);
    });
  }

  private init() {
    this.loadStatusColors().then(() => {
      this.availableStatus.forEach(status => this.showStatus[status] = true);
      const settings: Settings = this.dataService.getAccount().settings;
      if (settings.lat && settings.lng) {
        this.center = [settings.lat, settings.lng];
        this.hasStationLocation = true;
      }


      this.initMap().then(() => {
        this.initVehicles();
        if (this.enableAlarmMapFeature) {
          this.loadAlarmQueue();
          this.loadMissions();
        } else {
          // Always true if its not the alarm map
          this.displayTracking = true;
        }

      });

    });
  }

  /**
   * Init all listeners. Wait for map init
   */
  private initListeners() {
    this.listeners.push(this.$rootScope.$on('vehicle.location.change', (event, data: VehicleLocationChangeData) => {
      this.handleVehicleLocationChange(data);
    }));

    this.listeners.push(this.$rootScope.$on('status.change', (event, status: StatusEntryResponse) => {
      this.handleStatusChange(status);
    }))

    this.listeners.push(this.$rootScope.$on('deleted.assignedAlarm', (event, removedVehicle: VehicleRemovedFromAlarm) => {
      this.handleVehicleRemovedFromAlarm(removedVehicle);
    }));

    this.listeners.push(this.$rootScope.$on('queue.QUEUE_ENTRY_ADDED', (event, entry) => {
      if (this.enableAlarmMapFeature && entry) {
        this.addMarkerForEntry(entry as QueueEntryResponse);
        this.fitBounds(false);
      }
    }));

    this.listeners.push(this.$rootScope.$on('new.assignedAlarm.combined', (event, assignedAlarm: VehicleAssignedAlarmCombined) => {
      if (this.enableAlarmMapFeature && assignedAlarm) {
        this.addMissionMarkerForEntry(assignedAlarm, true);
      }
    }));

    this.listeners.push(this.$rootScope.$on('finished.mission', (event, mission: MissionRemovedFromMapResponse) => {
      if (this.enableAlarmMapFeature && mission) {
        this.removeMissionMarker(mission);
      }
    }));


    // Update marker on UPDATE event
    this.listeners.push(this.$rootScope.$on('queue.QUEUE_ENTRY_UPDATED', (event, entry) => {
      if (this.enableAlarmMapFeature) {
        let queueEntry = entry as QueueEntryResponse;
        let marker = this.markers.get(queueEntry.id);
        if (marker) {
          marker.setIcon(this.createDivIconForQueueMarker(queueEntry));
        }
      }
    }));

    // Remove marker on CLOSE event
    this.listeners.push(this.$rootScope.$on('queue.QUEUE_ENTRY_REMOVED', (event, entry) => {
      if (this.enableAlarmMapFeature) {
        let queueEntry = entry as QueueEntryResponse;
        let marker = this.markers.get(queueEntry.id);
        if (marker) {
          // Remove old layer
          this.queueLayerGroup.removeLayer(marker);
          this.markers.delete(queueEntry.id);
        }
        // Keep them on the map, but on different layer group
        this.addMarkerForEntry(queueEntry);
      }
    }));
  }

  createDivIconForQueueMarker(entry: QueueEntryResponse): L.divIcon {
    let color = 'gray';
    let cls = 'marker-pin';
    let additionalIcls = '';
    switch (entry.state) {
      case EQueueEntryState.IN_PROGRESS:
        if (entry.alarmData.parameters['keyword_color']) {
          color = entry.alarmData.parameters['keyword_color'];
        }
        break;
      case EQueueEntryState.ASSIGNED:
        cls = 'marker-pin-pulse';
        additionalIcls = 'marker-pin-pulse-i';
        break;
      case EQueueEntryState.DONE:
        color = '#343434';
        cls = 'marker-pin-finished';
        additionalIcls = 'marker-pin-finished-i';
        break;
    }
    let html: string;

    if (entry.emoji) {
      html = `<div style='background-color:${color}' class='${cls}'></div><i class='awesome ${additionalIcls}'>${entry.emoji}</i>`;
    } else {
      html = `<div style='background-color:${color}' class='${cls}'></div><i class='fas fa-siren-on awesome custom-div-icon-siren'></i>`;
    }
    return L.divIcon({
      className: 'custom-div-icon',
      iconSize: [30, 42],
      iconAnchor: [15, 42],
      html: html
    } as L.DivIconOptions);
  }

  createDivIconForMissionMarker(entry: VehicleAssignedAlarmCombined): L.divIcon {
    let color = entry.color;
    let cls = 'marker-pin';
    let additionalIcls = '';

    let html;
    if (entry.emoji) {
      html = `<div style='background-color:${color}' class='${cls}'></div><i class='awesome ${additionalIcls}'>${entry.emoji}</i><i style='background-color:${color}' class='custom-div-icon-mission-counter'>${entry.counter}</i>`;
    } else {
      html = `<div style='background-color:${color}' class='${cls}'></div><i class='fas fa-siren-on awesome custom-div-icon-siren'></i><i style='background-color:${color}' class='custom-div-icon-mission-counter'>${entry.counter}</i>`;
    }

    return L.divIcon({
      className: 'custom-div-icon',
      iconSize: [30, 42],
      iconAnchor: [15, 42],
      html: html
    } as L.DivIconOptions);
  }

  /**
   * Add a mission marker to the map
   */
  addMissionMarkerForEntry(entry: VehicleAssignedAlarmCombined, fitBounds: boolean): void {
    const key = `mission_${entry.externalId}`;
    const latLng = L.latLng(entry.lat, entry.lng);
    const alarmIcon = this.createDivIconForMissionMarker(entry);
    let existingMarker = this.markers.get(key);
    if (existingMarker) {
      existingMarker.setLatLng(latLng);
      existingMarker.setIcon(alarmIcon);
    } else {
      existingMarker = L.marker(latLng, { icon: alarmIcon, draggable: false });
      existingMarker.addTo(this.missionsLayerGroup);
      this.markers.set(key, existingMarker);
      if (fitBounds) {
        this.fitBounds(true);
      }
    }

    entry.vehicleAssignedToAlarmResponses.forEach(assignment => {
      this.vehicleIdMarkerMapping.set(assignment.vehicleId, existingMarker);
      this.missionMap.set(assignment.vehicleId, entry);
    });

    existingMarker.bindPopup(this.createHtmlForMissionPopUp(entry), {
      maxWidth: 200,
      maxHeight: 400,
      className: 'map-mission-popup',
      offset: [0, -26],
      closeButton: false
    });
  }

  createHtmlForMissionPopUp(entry: VehicleAssignedAlarmCombined) {
    // Vehicles
    let vehiclesHtml = '';
    entry.vehicleAssignedToAlarmResponses.forEach(assignment => {
      const singleVehicle = `
        <div class="map-mission-popup-vehicles-container">
          <div class="map-mission-popup-vehicles-container-status" style="background:${assignment.statusColor};color:${assignment.statusTextColor}">
            <span>${assignment.statusString}</span>
          </div>
          <span>
            ${assignment.vehicleName}
          </span>
        </div>
      `;
      vehiclesHtml += singleVehicle;
    });


    let missionExternalIds = `<div class="map-mission-popup-external-ids">
                                <span>${entry.externalId}</span>
                                <span>${entry.clock}</span>
                              </div>`;
    entry.additionalMissionsOnSameCoordinate.forEach(element => {
      missionExternalIds += `<div class="map-mission-popup-external-ids">
                                <span>${element.externalId}</span>
                                <span>${element.clock}</span>
                            </div>`;

      vehiclesHtml += `<div class="map-mission-popup-multiple"><span>${entry.vehicleAssignedToAlarmResponses[0].keyword}</span></div>`
      // Vehicles
      element.vehicleAssignedToAlarmResponses.forEach(assignment => {
        const singleVehicle = `
        <div class="map-mission-popup-vehicles-container">
          <div class="map-mission-popup-vehicles-container-status" style="background:${assignment.statusColor};color:${assignment.statusTextColor}">
            <span>${assignment.statusString}</span>
          </div>
          <span>
            ${assignment.vehicleName}
          </span>
        </div>
      `;
      vehiclesHtml += singleVehicle;
      });
    });



    const html = `
      <div class="map-mission-popup-container">
        <div class="map-mission-popup-header" style="background:${entry.color}"></div>
        <span class="map-mission-popup-keyword">${entry.vehicleAssignedToAlarmResponses[0].keyword}</span>
        <span class="map-mission-popup-location">${entry.vehicleAssignedToAlarmResponses[0].locationDest}</span>
        <div class="map-mission-popup-external-container">
            ${missionExternalIds}
        </div>
        </div>
        <div class="map-mission-popup-vehicles">
          <span class="map-mission-popup-vehicles-text">Einsatzmittel</span>
          <span class="map-mission-popup-vehicles-counter">${entry.counter}</span>
        </div>
        <div>
          ${vehiclesHtml}
        </div>
      </div>
    `;
    return html;
  }

  createHtmlForQueuePopUp(entry: QueueEntryResponse) {
    const html = `
      <div class="map-mission-popup-container">
        <div class="map-mission-popup-header" style="background:#c30b82"></div>
        <span class="map-mission-popup-keyword">${entry.alarmData.parameters['keyword']}</span>
        <span class="map-mission-popup-location">${entry.alarmData.locationDest}</span>
        <div class="map-mission-popup-external-container">
          <span>${entry.externalId}</span>
          <span>${entry.clock}</span>
        </div>
        <span class="map-mission-popup-queue-name">${entry.queueName}</span>
        <div class="map-mission-popup-queue-prio-container">
          <i class="fas fa-circle" style="color:${entry.priorityColor}"></i>
          <span>Priorität</span>
          <span class="map-mission-popup-queue-prio">${entry.priority}</span>
        </div>
        <div class="map-mission-popup-queue-time-container">
          <span class="map-mission-popup-queue-time">Erstellt</span>
          <span>${entry.creationTimeAsString}</span>
        </div>
        <div class="map-mission-popup-queue-time-container">
          <span class="map-mission-popup-queue-time">Abgeschlossen</span>
          <span>${entry.doneTimeAsString}</span>
        </div>
      </div>
    `;
    return html;
  }

  /**
   * Remove a mission from map
   */
  removeMissionMarker(mission: MissionRemovedFromMapResponse): void {
    const key = `mission_${mission.externalId}`;
    let marker = this.markers.get(key);
    if (marker) {
      this.missionsLayerGroup.removeLayer(marker);
      this.markers.delete(key);
      this.fitBounds(true);
    }
    // Remove mission from mission map
    let vehicleIdsToDelete: String[] = [];
    this.missionMap.forEach((missionFromMap, vehicleId) => {
      if (missionFromMap.externalId === mission.externalId) {
        vehicleIdsToDelete.push(vehicleId);
      }
    });
    vehicleIdsToDelete.forEach(vehicleId => this.missionMap.delete(vehicleId));
  }

  /**
   * Add QUEUE marker to map
   */
  addMarkerForEntry(entry: QueueEntryResponse) {
    const latLng = L.latLng(entry.alarmData.parameters['lat'], entry.alarmData.parameters['lng']);
    const alarmIcon = this.createDivIconForQueueMarker(entry);
    let marker = L.marker(latLng, { icon: alarmIcon, draggable: false });

    switch (entry.state) {
      case EQueueEntryState.DONE:
        marker.addTo(this.queueDoneLayerGroup);
        break;
      default:
        marker.addTo(this.queueLayerGroup);
        break;
    }

    this.markers.set(entry.id, marker);
    marker.bindPopup(this.createHtmlForQueuePopUp(entry), {
      maxWidth: 200,
      maxHeight: 400,
      className: 'map-mission-popup',
      offset: [0, -26],
      closeButton: false
    });
  }

  // Load queue alarms
  loadAlarmQueue() {
    if (!this.privilegeService.has(RolePrivilege.Alarm_Queues)) {
      return;
    }
    this.restService.loadAllQueueEntries()
      .then((result) => {

        if (this.markers.size > 0) {
          this.markers.forEach((marker, id) => this.map.removeLayer(marker));
        }

        var queueEntryResponses = result.filter(entry => entry.hasMap);
        queueEntryResponses.forEach(entry => {
          this.addMarkerForEntry(entry);
        });
        // if no entries are in this filtered results the fit bounds will cause an error undefined for fitbounds on undefined
        if (queueEntryResponses.length>0) {
         this.fitBounds(false);
        }
      })
      .finally(() => {
        this.$scope.$applyAsync();
      });
  }

  /**
   * Load current missions from backend
   */
  loadMissions() {
    if (!this.privilegeService.has(RolePrivilege.Home_Alarms_List)) {
      return;
    }
    this.restService.loadAllVehiclesWithAlarmCombined().then(result => {
      result.forEach(entry => this.addMissionMarkerForEntry(entry, false));
      setTimeout(() => {
        if (this.displayMissions) {
          // We have some issues displaying the layer right after loading. therefore we wait a little and refresh the layer
          this.map.removeLayer(this.missionsLayerGroup);
          this.missionsLayerGroup.addTo(this.map);
        }
      }, 1500);
      // here the same if no values are found for missions do not call fit bounds
      if (this.displayMissions && result.length>0) {
        this.fitBounds(true);
      }
    });
  }

  /**
   * Fit bounds either mission or queue layer, depending of the event
   */
  fitBounds(fromMission: boolean): void {
    if (!this.fitBoundsActive) return;
    // Only can fit one layer
    let layerToFit = this.queueLayerGroup.getBounds();
    if (fromMission) {
      layerToFit = this.missionsLayerGroup.getBounds();
    }
      this.map.fitBounds(layerToFit, {
        maxZoom: 15
      } as L.FitBounds);
  }

  private handleStatusChange(status: StatusEntryResponse) {
    const vehicleHolder = this.vehicleIdMapping[status.vehicleId];
    // Update vehicle flag
    if (vehicleHolder) {
      this.showOrHideVehicleDependingOnStatus(vehicleHolder, status.status);
      vehicleHolder.vehicle.status = status.status;
      this.updateVehicleMarker(vehicleHolder);
    }

    const vehicleId = status.vehicleId;

    // Update mission popup
    let marker = this.vehicleIdMarkerMapping.get(vehicleId);
    if (marker) {
      let mission = this.missionMap.get(vehicleId);
      if (mission) {
        let element = mission.vehicleAssignedToAlarmResponses.filter(assignment => assignment.vehicleId === vehicleId);
        if (element.length > 0) {
          element[0].status = status.status;
          element[0].statusColor = status.color;
          element[0].statusTextColor = status.textColor;
          element[0].statusString = status.statusString;
        }
        marker.setPopupContent(this.createHtmlForMissionPopUp(mission));
      } else {
        // Mission does not exist, cannot update
      }
    }
  }

  private handleVehicleLocationChange(data: VehicleLocationChangeData) {
    const vehicleHolder = this.vehicleIdMapping[data.vehicleId];
    if (!vehicleHolder) {
      this.handleNewVehicleDuringRuntime(data);
      return;
    }

    this.showOrHideVehicleDependingOnStatus(vehicleHolder, data.status);
    this.updateVehicleData(vehicleHolder, data);
    this.updateVehicleMarker(vehicleHolder);

    // workaround fix because of github thread markercluster verison <1.0.0 does not update on moving markers properly with just setLatLng
    // https://github.com/Leaflet/Leaflet.markercluster/issues/649 // not the best solution because of extensive calc
    this.trackingClusterGroup.removeLayer(vehicleHolder.marker);
    vehicleHolder.marker.setLatLng([data.lat, data.lng]);
    this.addToMapLayer(data.status, vehicleHolder);

  }

  private addToMapLayer(vehicleStatus: EVehicleStatus, vehicleHolder) {
    if (this.isStatusActivated(vehicleStatus)) {
      vehicleHolder.marker.addTo(this.trackingClusterGroup);
    }
  }

  private handleNewVehicleDuringRuntime(data: VehicleLocationChangeData) {
    if (!this.privilegeService.has(RolePrivilege.Station_Vehicles_Location)) {
      return;
    }
    // Element does not yet exist, load as response (change event misses data like vehicle name etc)
    this.restService.loadVehicleLocation(data.vehicleId)
      .then((locationResponse) => this.initVehicleDuringTracking(locationResponse))
      .then((newHolder) => this.showOrHideVehicleDependingOnStatus(newHolder, data.status))
  }

  /**
   * @param vehicleHolder the vehicle for which the update should be done, must contain the old status
   * @param newStatus the new status of the vehicle
   */
  private showOrHideVehicleDependingOnStatus(vehicleHolder: any, newStatus: EVehicleStatus) {
    const vehicleCurrentlyShown = this.isStatusActivated(vehicleHolder.vehicle.status);
    const shouldShowVehicle = this.isStatusActivated(newStatus);

    if (vehicleCurrentlyShown && !shouldShowVehicle) {
      this.trackingClusterGroup.removeLayer(vehicleHolder.marker);
    } else if (!vehicleCurrentlyShown && shouldShowVehicle) {
      this.addToMapLayer(newStatus, vehicleHolder);
    }
  }

  private updateVehicleMarker(vehicleHolder: VehicleMarkerHolder) {
    vehicleHolder.marker.setIcon(this.buildVehicleMarkerIcon(vehicleHolder.vehicle));
    vehicleHolder.marker.setPopupContent(this.buildVehiclePopUp(vehicleHolder.vehicle));
    this.redrawClusters();
  }

  private updateVehicleData(vehicleHolder: VehicleMarkerHolder, data: VehicleLocationChangeData) {
    vehicleHolder.vehicle.lat = data.lat;
    vehicleHolder.vehicle.lng = data.lng;
    vehicleHolder.vehicle.timestamp = data.timestamp;
    vehicleHolder.vehicle.altitude = data.alt;
    vehicleHolder.vehicle.status = data.status;
  }

  private initVehicles() {
    if (!this.privilegeService.has(RolePrivilege.Station_Vehicles_Location)) {
      return;
    }
    this.restService.loadVehicleLocationsForUser().then(vehicles => {
      vehicles.forEach(vehicle => this.initVehicle(vehicle));
    });
  }

  private initVehicle(vehicle: VehicleLocationResponse) {
    // always create marker
    const marker = this.createVehicleMarker(vehicle);
    // handle it this way so only one access point creating and adding marker to holder map (do not lose ref to one marker placed)
    this.addMarkerToMapping(vehicle, marker).then((value) => {
      this.addToMapLayer(vehicle.status, value);
    })


  }

  private initVehicleDuringTracking(vehicle: VehicleLocationResponse): Promise<VehicleMarkerHolder> {
    return new Promise<VehicleMarkerHolder>((resolve, _) => {
      var marker = this.createVehicleMarker(vehicle);

      this.addMarkerToMapping(vehicle, marker).then((markerSaved) => {
        resolve(markerSaved);
      })
    });
  }

  private addMarkerToMapping(vehicle: VehicleLocationResponse, marker) {
    return new Promise<VehicleMarkerHolder>(async (resolve, _) => {
      var markerHolder = await this.addMappingInner(vehicle, marker);
      resolve(markerHolder);
    });
  }


  private addMappingInner(vehicle: VehicleLocationResponse, marker): VehicleMarkerHolder {
    var mapping = this.vehicleIdMapping[vehicle.id];
    if (mapping) {
      return mapping;
    }
    const holder = { vehicle, marker } as VehicleMarkerHolder
    this.vehicleIdMapping[holder.vehicle.id] = holder;
    return holder;
  }

  private createVehicleMarker(vehicle: VehicleLocationResponse) {
    const icon = this.buildVehicleMarkerIcon(vehicle);
    const position = [vehicle.lat, vehicle.lng];
    const marker = L.marker(position, { icon, title: vehicle.name });

    marker.bindPopup(this.buildVehiclePopUp(vehicle), {
      maxWidth: 200,
      maxHeight: 250,
      className: 'map-mission-popup',
      offset: [0, -20],
      closeButton: false
    });

    return marker;

  }

  private buildVehicleMarkerIcon(vehicle: VehicleLocationResponse) {
    return L.divIcon({
      className: 'tracking-marker',
      html: this.buildVehicleMarkerHtml(vehicle),
      popupAnchor: [0, -25]
    });
  }

  private buildVehicleMarkerHtml(vehicle: VehicleLocationResponse) {
    return '<div class="tracking-icon-content" style="background: ' + this.getStatusColor(vehicle.status) + ';color: ' + this.getStatusTextColor(vehicle.status) + ';">' +
      '<div class="tracking-icon-content-status">' + this.getStatusTranslation(vehicle.status) + '</div>' +
      '<div class="tracking-icon-content-data">' +
      '<div class="tracking-icon-content-data-name">' + vehicle.name + '</div>' +
      '</div>' +
      '</div>';
  }

  private loadStatusColors() {
    return new Promise<void>((resolve, _) => {
      if (!this.privilegeService.has(RolePrivilege.Station_Vehicles)) {
        resolve();
        return;
      }
      this.vehicleService.getStatusColorMapping()
        .then(statusColorMapping => this.statusColorMapping = statusColorMapping)
        .then(() => this.vehicleService.getStatusTextColorMapping())
        .then(statusTextColorMapping => this.statusTextColorMapping = statusTextColorMapping)
        .then(() => this.vehicleService.getStatusTranslationMapping())
        .then(statusTranslationMapping => this.statusTranslationMapping = statusTranslationMapping)
        .catch(error => this.$log.error(error))
        .finally(() => {
          this.$scope.$applyAsync();
          resolve();
        })
    })
  }


  private initMap():Promise<void> {
   return new Promise<void>((resolve) =>{
     L.Icon.Default.imagePath = '/img/static';

    const center = this.center;

    this.map = L.map('wache-map', {
      worldCopyJump: true
    });
    this.map.setView(center, 15);

    this.map.on('drag', () => {
      this.fitBoundsActive = false;
    });

    this.initLayers();

    if (this.hasStationLocation) {
      this.showStation(center);
    }

    this.initClustering();

    if (this.enableAlarmMapFeature) {
      // Add Marker Layer Group
      this.queueLayerGroup = new L.featureGroup();
      this.queueDoneLayerGroup = new L.featureGroup();
      this.missionsLayerGroup = new L.featureGroup();
      // Add Overlays
      this.rainRadarLayer = L.tileLayer.wms('https://maps.dwd.de/geoserver/dwd/wms', {
        maxZoom: 19,
        id: 'Regenradar',
        attribution: '© Deutscher Wetterdienst',
        layers: 'dwd:Niederschlagsradar',
        format: 'image/png',
        transparent: true,
        opacity: 0.5,
        zIndex: 11
      });


      // Add DWD warnings
      this.warningsLayer = L.tileLayer.wms('https://maps.dwd.de/geoserver/dwd/wms', {
        maxZoom: 19,
        id: 'Warnungen',
        attribution: '© Deutscher Wetterdienst',
        layers: 'dwd:Warnungen_Gemeinden',
        format: 'image/png',
        transparent: true,
        opacity: 0.5,
        zIndex: 11
      });

      if (this.displayRainRadar) {
        this.rainRadarLayer.addTo(this.map);
      }

      if (this.displayWarnings) {
        this.warningsLayer.addTo(this.map);
      }

      if (this.displayQueueNewAndInProgress) {
        this.queueLayerGroup.addTo(this.map);
      }
      if (this.displayMissions) {
        this.missionsLayerGroup.addTo(this.map);
      }
      if (this.displayQueueDone) {
        this.queueDoneLayerGroup.addTo(this.map);
      }

      // Schedule updater
      this.refreshTimer = setInterval(() => {
        // Refreshes the layer
        this.rainRadarLayer.setParams({
          fake: Date.now()
        } as any, false);

        this.warningsLayer.setParams({
          fake: Date.now()
        } as any, false);
      }, 1000 * 60 * 5); // Every 5 minutes
    }

    // No init listeners
    this.initListeners();
    resolve();
   });
  }

  initClustering() {
    this.trackingClusterGroup = L.markerClusterGroup({
      // this function is used for customizing the style of the cluster icon
      // we reuse the html of all the marker labels
      iconCreateFunction: (cluster) => {
        const padding = 15;
        const maxNumberOfVehiclesPerColumn = 3;
        const heightPerVehicleLabel = 25;
        const computedMaxHeight = maxNumberOfVehiclesPerColumn * heightPerVehicleLabel;
        let text = '<div class="leaflet-clustered-divicon-div" style="max-height:' + computedMaxHeight + 'px;">';
        cluster.getAllChildMarkers().forEach(marker => {
          text += (marker.options.icon.options as any).html;
        });
        text += '</div>';
        const anchorY = (heightPerVehicleLabel
          * (cluster.getChildCount() >= maxNumberOfVehiclesPerColumn ? maxNumberOfVehiclesPerColumn : cluster.getChildCount())) + padding;
        return L.divIcon({
          html: text,
          className: 'leaflet-clustered-divicon',
          iconAnchor: [0, anchorY]
        });
      },
      // this function is used customizing the spiderfy behavior (not really used in amweb)
      spiderfyShapePositions: (count, centerPt) => {
        const distanceFromCenter = 15;
        const markerDistance = 20;
        const lineLength = markerDistance * (count - 1);
        const lineStart = centerPt.y - lineLength / 2;
        const res = [];
        let i;

        res.length = count;

        for (i = count - 1; i >= 0; i--) {
          res[i] = new L.Point(centerPt.x + distanceFromCenter, lineStart + markerDistance * i);
        }

        return res;
      }
    });

    if (!this.enableAlarmMapFeature || this.displayTracking) {
      this.trackingClusterGroup.addTo(this.map);
    }
  }

  private showStation(center: number[]) {
    const icon = L.icon({
      iconUrl: 'img/static/firestation.png',
      iconSize: [32, 37],
      iconAnchor: [16, 37]
    });
    L.marker(center, { icon }).addTo(this.map);
  }

  private initLayers() {
    const mapService = this.mapService;
    const layers = mapService.getBaseLayersForTracking();
    L.control.layers(layers).addTo(this.map);

    let selectedLayer = mapService.getSelectedLayer();
    if (selectedLayer == undefined || layers[selectedLayer] == undefined) {
      selectedLayer = "OpenStreetMap";
    }
    layers[selectedLayer].addTo(this.map);
    this.map.on('baselayerchange', function (e) {
      mapService.saveLayer(e.name);
    });
  }

  public toggleStatus(status: EVehicleStatus) {
    const isToggled = !this.isStatusActivated(status);
    this.showStatus[status] = isToggled;
    // redraw all vehicles with the changed status
    Object.values(this.vehicleIdMapping)
      .filter(holder => holder.vehicle.status == status)
      .forEach(holder => {
        if (isToggled) {
          this.addToMapLayer(status, holder)
        } else {
          this.trackingClusterGroup.removeLayer(holder.marker);
        }
      });
  }

  public isStatusActivated(status: EVehicleStatus) {
    if (this.alwaysShowStatus.has(status)) return true;
    return this.showStatus[status] ?? false;
  }

  public getStatusStyle(status: EVehicleStatus) {
    const backgroundColor = this.statusColorMapping[status] ?? "red";
    const textColor = this.statusTextColorMapping[status] ?? "white";
    return {
      'background-color': backgroundColor,
      'color': textColor
    }
  }

  private redrawClusters(): void {
    if (this.displayTracking) {
      this.trackingClusterGroup?.refreshClusters();
    }
  }

  private buildVehiclePopUp(vehicle: VehicleLocationResponse): string {
    const statusTranslation = this.statusTranslationMapping ? this.statusTranslationMapping[vehicle.status] : vehicle.status;

    let html = `
      <div class="map-mission-popup-container">
        <div class="map-mission-popup-header" style="background:${vehicle.statusColor}"></div>
        <span class="map-mission-popup-keyword">${vehicle.name}</span>
        <span class="map-mission-popup-location"><b>${vehicle.statusValue}</b>${statusTranslation}</span>
    `;

    if (vehicle.statusTimestampString) {
      html += `<span class="map-mission-popup-location">${vehicle.statusTimestampString}</span>`;
    }

    if (vehicle.source) {
      html += `<span class="map-mission-popup-location">${vehicle.source}</span>`;
    }

    if (vehicle.altitude) {
      html += `<span class="map-mission-popup-location">${vehicle.altitude} m</span>`;
    }

    html += '</div>';

    html += `
      <div class="map-mission-popup-location">
        <span>${vehicle.timestampString}</span>
        <i class="fas fa-crosshairs"></i>
      </div>`;
    
    

    return html;
  }

  private getStatusColor(status: EVehicleStatus): string {
    return this.statusColorMapping[status] ?? "white";
  }

  private getStatusTextColor(status: EVehicleStatus): string {
    return this.statusTextColorMapping[status] ?? "black";
  }

  private getStatusTranslation(status: EVehicleStatus): string {
    switch (status) {
      case EVehicleStatus.STATUS_0:
        return '0';
      case EVehicleStatus.STATUS_1:
        return '1';
      case EVehicleStatus.STATUS_2:
        return '2';
      case EVehicleStatus.STATUS_3:
        return '3';
      case EVehicleStatus.STATUS_4:
        return '4';
      case EVehicleStatus.STATUS_5:
        return '5';
      case EVehicleStatus.STATUS_6:
        return '6';
      case EVehicleStatus.STATUS_7:
        return '7';
      case EVehicleStatus.STATUS_8:
        return '8';
      case EVehicleStatus.STATUS_9:
        return '9';
      case EVehicleStatus.STATUS_C:
        return 'c';
      default:
        return status + '';
    }
  }

  private handleVehicleRemovedFromAlarm(removedVehicle: VehicleRemovedFromAlarm) {
    const mapping = this.vehicleIdMapping[removedVehicle.vehicleId];
    if (!mapping) {
      return;
    }
    var mode = mapping.vehicle.mode;
    if (mode === VehicleTrackingMode.ALWAYS) {
      return;
    }

    this.waitAWhileBeforeRemoval(mapping);

  }

  private waitAWhileBeforeRemoval(mapping: VehicleMarkerHolder) {
    // wait a little first for late events already in tracking graph and to avoid errors to recreate marker
    this.delay(2000).then(() => {
      this.trackingClusterGroup.removeLayer(mapping.marker);
      this.vehicleIdMapping.delete(mapping.vehicle.id);
    })

  }
  private delay(ms: number): Promise<void> {
    return new Promise(resolve => setTimeout(resolve, ms))
  }

  /**
   * Toggle weather warnings
   */
  public toggleWarnings(): void {
    this.displayWarnings = !this.displayWarnings;
    if (this.displayWarnings) {
      this.warningsLayer.addTo(this.map);
    } else {
      this.map.removeLayer(this.warningsLayer);
    }
    this.helperService.saveInStorage('displayWarnings', this.displayWarnings);
  }

  /**
   * Toggle rain radar
   */
  public toggleRainRadar(): void {
    this.displayRainRadar = !this.displayRainRadar;
    if (this.displayRainRadar) {
      this.rainRadarLayer.addTo(this.map);
    } else {
      this.map.removeLayer(this.rainRadarLayer);
    }
    this.helperService.saveInStorage('displayRainRadar', this.displayRainRadar);
  }

  /**
   * Toggle tracking layer
   */
  public toggleTracking(): void {
    this.displayTracking = !this.displayTracking;
    if (this.displayTracking) {
      // Init cluster
      this.initClustering();
      // Add all existing markers to the cluster
      for (const key in this.vehicleIdMapping) {
        const markerHolder = this.vehicleIdMapping[key] as VehicleMarkerHolder;

        // Check for status filter

        const vehicleCurrentlyShown = this.isStatusActivated(markerHolder.vehicle.status);
        if (vehicleCurrentlyShown) {
          markerHolder.marker.addTo(this.trackingClusterGroup);
        }
      }
    } else {
      this.map.removeLayer(this.trackingClusterGroup);
    }
    this.helperService.saveInStorage('displayTracking', this.displayTracking);
  }

  /**
   * Toggle queue NEW and IN_PROGRESS
   */
  public toggleQueueNewAndInProgress(): void {
    this.displayQueueNewAndInProgress = !this.displayQueueNewAndInProgress;
    if (this.displayQueueNewAndInProgress) {
      this.queueLayerGroup.addTo(this.map);
    } else {
      this.map.removeLayer(this.queueLayerGroup);
    }
    this.helperService.saveInStorage('displayQueueNewAndInProgress', this.displayQueueNewAndInProgress);
  }

  /**
   * Toggle queue DONE entries
   */
  public toggleQueueDone(): void {
    this.displayQueueDone = !this.displayQueueDone;
    if (this.displayQueueDone) {
      this.queueDoneLayerGroup.addTo(this.map);
    } else {
      this.map.removeLayer(this.queueDoneLayerGroup);
    }
    this.helperService.saveInStorage('displayQueueDone', this.displayQueueDone);
  }

  /**
   * Toggle missions as layer
   */
  public toggleMissions(): void {
    this.displayMissions = !this.displayMissions;
    if (this.displayMissions) {
      this.missionsLayerGroup.addTo(this.map);
    } else {
      this.map.removeLayer(this.missionsLayerGroup);
    }
    this.helperService.saveInStorage('displayMissions', this.displayMissions);
  }
}